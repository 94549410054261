<template>
    <div>
        <b-modal
            id="daily-quiz-modal"
            ref="daily-quiz-modal"
            :hide-header="true"
            :hide-footer="true"
            :visible="true"
            @show="initData"
        >
            <div>
                <b-row>
                    <b-col lg="3" />
                    <b-col lg="9">
                        <b-container fluid>
                            <b-row class="close-icon mb-1 justify-content-end pr-5"><span @click="hideModal()"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></span></b-row>
                            <b-row class="mb-3 vertical-align">
                                <b-col lg="1">
                                    <div class="owl-bg">
                                        <owl-svg />
                                    </div>
                                </b-col>
                                <b-col lg="7" class="instructions">{{ $t('quiz.quizDailyInstructions') }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="8" class="daily-quiz-container m-1">
                                    <b-progress class="mt-1" :max="task.duration">
                                        <b-progress-bar :value="task.duration - secondsLeft" />
                                    </b-progress>
                                    <b-row class="justify-content-center mt-5 timer">
                                        <span>{{ $t('taskTopBar.timer') }} : </span>
                                        <span class="ml-2">
                                            {{ timeLeft }}
                                        </span>
                                    </b-row>
                                    <b-row class="justify-content-center"><span class="task-alias">{{ alias }}</span></b-row>
                                    <b-row class="justify-content-center mt-2"><img src="@/assets/images/quiz-icons/question_icon.png" alt=""></b-row>
                                    <b-row class="justify-content-center mt-4"><span class="question-title">{{ question.content }}</span></b-row>
                                    <b-container class="w-75 mt-1">
                                        <div
                                            v-for="answer of question.answers"
                                            :key="answer.id"
                                            @click="selectAnswer(answer)"
                                        >
                                            <quiz-answer
                                                :answer="answer"
                                                :class="{
                                                    'not-clickable': selectedAnswer,
                                                    'fifty-fifty-disabled': isFiftyFiftyDisabledAnswer(answer)
                                                }"
                                                :is-correct="answer.correct"
                                                :is-answer-visible="answerIsVisible(answer)"
                                            />
                                        </div>
                                    </b-container>
                                </b-col>
                                <b-col>
                                    <b-container>
                                        <b-row class="mb-3 mr-4 vertical-align help" :class="{ 'help-disabled': !hasNextHelp }" @click="useHelp('NEXT')">
                                            <img src="@/assets/images/quiz-icons/change.png" alt="change icon" class="mr-2">
                                            <span class="help-text">{{ $t('quiz.changeQuestionHelp') }} </span>
                                        </b-row>
                                        <b-row class="mb-3 mr-4 vertical-align help" :class="{ 'help-disabled': !hasTipHelp }" @click="useHelp('TIP')">
                                            <img src="@/assets/images/quiz-icons/tips.png" alt="tips icon" class="mr-2">
                                            <span class="help-text">{{ $t('quiz.tipOnQuestionHelp') }}</span>
                                        </b-row>
                                        <b-row v-if="showTip" class="mb-3 vertical-align">
                                            <b-col class="tip-text">
                                                <span>{{ question.help }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-3 mr-4 vertical-align help" :class="{ 'help-disabled': !hasFiftyHelp }" @click="useHelp('FIFTY')">
                                            <img src="@/assets/images/quiz-icons/fifty-fifty.png" alt="50-50 icon" class="mr-2">
                                            <span class="help-text">{{ $t('quiz.fiftyfiftyHelp') }}</span>
                                        </b-row>
                                    </b-container>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-col>
                    <b-col />
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>

    import quizService from '@/services/quiz-service'
    import QuizAnswer from '@/components/QuizAnswer'
    import missionService from '@/services/mission-service'
    import taskUtil from '@/utils/task-util'
    import OwlSvg from '@/components/svg/OwlSVG'
    export default {
        name: 'DailyQuizRoute',
        components: { QuizAnswer, OwlSvg },

        data() {
            return {
                dailyQuiz: {},
                selectedAnswer: null,
                interval: null,
                secondsLeft: 0,
                timer: null,
                showTip: false,
                fiftyfiftyIncorrectAnswer: {},
                fiftyFiftyMode: false
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return (missionId && taskId) ? this.$store.getters.getTask(missionId, taskId) : null
            },
            mission() {
                return this.$store.getters.getMission(parseInt(this.$route.params.missionId))
            },
            question() {
                if (this.dailyQuiz && this.dailyQuiz.id) {
                    return this.dailyQuiz.questions[0]
                } else {
                    return {}
                }
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            hasNextHelp() {
                return (this.$store.getters.isPreviewMode || !this.playerHelpsContain('NEXT')) && this.mission.dailyQuizHelpQuestion
            },
            hasFiftyHelp() {
                return this.$store.getters.isPreviewMode || !this.playerHelpsContain('FIFTY')
            },
            hasTipHelp() {
                return this.$store.getters.isPreviewMode || !this.playerHelpsContain('TIP')
            },
            timeLeft() {
                return `${this.minutes} : ${this.seconds} `
            },
            minutes() {
                return String(Math.floor(this.secondsLeft / 60)).padStart(2, '0')
            },
            seconds() {
                return String(this.secondsLeft % 60).padStart(2, '0')
            },
            alias() {
                if (!this.task.multilingualTask || !this.task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_ALIAS'
                }
                return this.task.multilingualTask[0].config.alias
            }
        },
        watch: {
            task() {
                this.initData()
            }
        },
        created() {
            this.preventGoBack()
        },
        beforeDestroy() {
            window.removeEventListener('popstate', function() { })
        },
        methods: {
            playerHelpsContain(helpType) {
                if (this.player && this.player.playerHelps) {
                    for (let playerHelp of this.player.playerHelps) {
                        let missionId = parseInt(this.$route.params.missionId)
                        if (playerHelp.mission && playerHelp.mission.id === missionId && playerHelp.alias === helpType) {
                            return true
                        }
                    }
                }
                return false
            },
            useHelp(helpType) {
                if (!this.$store.getters.isPreviewMode) {
                    let missionId = parseInt(this.$route.params.missionId)
                    let taskId = parseInt(this.$route.params.taskId)
                    missionService.requestHelpOnTask(missionId, taskId, helpType)
                }
                if (helpType === 'TIP' && this.hasTipHelp) {
                    this.showTip = true
                } else if (helpType === 'NEXT' && this.hasNextHelp) {
                    this.showTip = false
                    this.fiftyfiftyIncorrectAnswer = {}
                    this.dailyQuiz.questions = [this.mission.dailyQuizHelpQuestion]
                } else if (helpType === 'FIFTY' && this.hasFiftyHelp) {
                    this.fiftyfiftyIncorrectAnswer = this.chooseRandomIncorrectAnswer()
                    this.fiftyFiftyMode = true
                }
            },
            initData() {
                this.secondsLeft = this.task.duration
                if (this.task && this.task.taskType && this.task.taskType.alias === 'QUIZ_DAILY') {
                    quizService.getQuiz(this.task.quizzes[0].id).then((res) => {
                        this.dailyQuiz = res.data
                        this.startTimer()
                    })
                }
            },
            chooseRandomIncorrectAnswer() {
                let incorrectAnswer = null
                while (incorrectAnswer === null) {
                    let chosenAnswer = this.question.answers[Math.floor(Math.random() * 4)]
                    if (!chosenAnswer.correct) {
                        incorrectAnswer = chosenAnswer
                    }
                }
                return incorrectAnswer
            },
            isFiftyFiftyDisabledAnswer(answer) {
                return this.fiftyFiftyMode && (this.fiftyfiftyIncorrectAnswer.id !== answer.id && !answer.correct)
            },
            hideModal() {
                this.killTimer()
                this.$refs['daily-quiz-modal'].hide()
                this.$router.push({ path: `/home` })
            },
            selectAnswer(answer) {
                if (this.selectedAnswer) {
                    return false
                }
                if (this.fiftyFiftyMode && this.isFiftyFiftyDisabledAnswer(answer)) {
                    return false
                }
                setTimeout(() => {
                    if (answer.correct) {
                        this.submitPoints(this.task.points, true)
                    } else {
                        this.submitPoints(0, true)
                    }
                }, 2000)
                this.selectedAnswer = answer
            },
            answerIsVisible(answer) {
                return (this.selectedAnswer && answer.correct) || (this.selectedAnswer === answer && !answer.correct)
            },
            startTimer() {
                this.secondsLeft = this.task.duration
                this.interval = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1
                    } else {
                        this.submitPoints(0, true)
                    }
                }, 1000)
            },
            killTimer() {
                if (this.task) {
                    this.secondsLeft = this.task.duration
                }
                clearInterval(this.interval)
            },
            submitPoints(points, finished) {
                taskUtil.updateTask(this.mission, this.task, points, finished, this.$router)
                this.killTimer()
            },
            preventGoBack() {
                window.removeEventListener('popstate', function() { })
                history.pushState(null, null, document.URL)
                window.addEventListener('popstate', function() {
                    history.pushState(null, null, document.URL)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.progress-bar {
    background-color: var(--info-color);
    height: 10px;
    border-radius: 20px;
    box-shadow: 5px 3px 27px -3px var(--black);
}

.progress {
    height: 20px;
    background-color: var(--white);
    border-radius: 20px;
    padding: 5px;
}

.fifty-fifty-disabled {
    opacity: 0.3;
    cursor: default;
}

.help {
    padding: 5px;
    color: var(--white);
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.help:not(.help-disabled):hover {
    border-radius: 35px;
    background: var(--secondary-color);
}

.help.help-disabled {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
}

.help-text {
    display: none;
}

.help:not(.help-disabled):hover .help-text {
    display: block;
}

.tip-text {
    padding: 10px;
    margin-left: 3%;
    margin-right: 3%;
    color: var(--white);
    font-weight: 700;
    border-radius: 15px;
    border: 1px solid var(--white);
    background: rgb(255 255 255 / 10%);
}

.daily-quiz-container {
    text-align: center;
    background: url("~@/assets/images/quiz-icons/daily-quiz-background.png")  center center;
    border-radius: 15px;
    width: 900px;
    height: 600px;
}

.instructions {
    text-align: left;
    color: var(--white);
    font-weight: 700;
    font-size: 14px;
}

.close-icon {
    color: var(--white);
    font-weight: 700;
}

.close-icon svg {
    font-size: 40px;
}

.close-icon:hover {
    color: var(--error);
    cursor: pointer;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.task-alias {
    font-weight: 700;
    font-size: 14px;
    color: var(--highlight-color-2);
    margin-top: 3%;
}

.question-title {
    font-weight: 700;
    font-size: 22.4px;
    color: var(--black);
}

.not-clickable {
    pointer-events: none;
}

.timer {
    font-weight: 700;
    font-size: 15px;
    color: var(--white);
}

.owl-bg {
    background: var(--secondary-color);
    padding: 0.5rem;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 768px) {
    .help {
        background: var(--secondary-color);
        border-radius: 35px;
    }

    .help-text {
        display: block;
    }
}
</style>

<style lang="scss" >
#daily-quiz-modal {
    background: rgb(0 0 0 / 50%);
}

#daily-quiz-modal .modal-dialog {
    padding-top: 80px;
    max-width: 100% !important;
    margin: 0 !important;
}

#daily-quiz-modal .modal-body {
    max-width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
}

#daily-quiz-modal .modal-content {
    border: none !important;
    background-color: transparent !important;
}

</style>
